/* -------------------------------------------------------------------------------
    Variables
   ------------------------------------------------------------------------------- */
.bg-primary {
  background-color: #ed1c24 !important;
}
.bg-darker-grey {
  background-color: #333 !important;
}
.bg-dark-grey {
  background-color: #888 !important;
}
.bg-grey {
  background-color: #c6c6c6 !important;
}
.bg-light-grey {
  background-color: #f6f6f6 !important;
}
.bg-lighter-grey {
  background-color: #f5f5f5 !important;
}
.cl-primary {
  color: #ed1c24;
}
.cl-black {
  color: #3e0000;
}
.cl-grey {
  color: #ccc;
}
/* -------------------------------------------------------------------------------
    HTML Elements
   ------------------------------------------------------------------------------- */
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  position: relative;
  min-height: 100.1%;
  font-family: 'Open Sans', sans-serif;
  color: #3e0000;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
blockquote,
th {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-weight: 700;
}
:focus,
:visited,
:hover,
:active,
a:focus,
a:visited,
a:hover,
a:active {
  outline: 0 !important;
  text-decoration: none;
}
/* -------------------------------------------------------------------------------
    Custom Helpers
   ------------------------------------------------------------------------------- */
.bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.uppercase {
  text-transform: uppercase;
}
.uppercase a {
  text-transform: uppercase;
}
.shadow {
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1) !important;
}
.overlay {
  display: none;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
}
.image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
}
.image-frame {
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: 0.25rem;
  padding: 5px;
}
.w-auto {
  width: auto !important;
}
.center-absolute-x {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.center-absolute-y {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.center-absolute-xy {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.center-block-x {
  margin-left: auto !important;
  margin-right: auto !important;
}
.center-flex-y {
/* add class to parent of centred content */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.center-flex-xy {
/* add class to parent of centred content */
  display: flex;
  justify-content: center;
  align-items: center;
}
.variations-table th {
  text-transform: none;
  padding-top: 0;
}
.variations-table td {
  font-size: 12px;
  padding-right: 0;
}
.ring-container {
  position: relative;
  top: -38px;
  left: -41px;
}
.circle {
  width: 15px;
  height: 15px;
  background-color: #ed1c24;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  left: 20px;
}
.ringring {
  border: 3px solid #ed1c24;
  -webkit-border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  left: 15px;
  top: 15px;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
.bg-light {
  background-color: #f4f5f4 !important;
}
/* -------------------------------------------------------------------------------
    Components
   ------------------------------------------------------------------------------- */
.cookie-bar {
  position: fixed;
  bottom: 0px;
  color: #fff f;
  background-color: #262626;
  z-index: 9;
}
.cookie-bar .copy {
  width: 90%;
  padding-left: 88px;
  padding-top: 20px;
  margin-bottom: 8px;
}
.cookie-bar .copy p {
  color: #fff !important;
  font-size: 14px;
}
.cookie-bar .cookie-close {
  position: relative;
  font-size: 24px;
}
.cookie-bar .cookie-close i {
  color: #ed1c20;
  margin-top: -85px;
  float: right;
  margin-right: -50px;
}
.cookie-bar a {
  color: #888;
}
.page {
  min-height: auto;
}
.component {
  position: relative;
  padding-top: 3em;
  padding-bottom: 3em;
}
.component-calls-to-action,
.component-carousel,
.component-content,
.component-form-all,
.component-form-contact,
.component-centered-text,
.component-text-image,
.component-image-text,
.component-slider,
.component-portfolio,
.component-tabs,
.component-gallery,
.component-galleries,
.spaced {
  padding-top: 3em;
  padding-bottom: 3em;
}
.component-header header {
  min-height: 100vh;
}
#filterProducts .custom-select {
  border: none;
  box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.04);
}
#filterProducts .custom-select:focus {
  outline: 0;
  box-shadow: none;
}
/* Carousel & Slider */
.slick-slide {
  padding: 0 1em;
}
.slick-list {
  margin: 0 -1em;
}
.product-image-slider .carousel-control-next,
.product-image-slider .carousel-control-prev {
  filter: grayscale(100%) brightness(0);
  z-index: 100;
}
.carousel .carousel-inner .carousel-item {
  height: 50vw;
  overflow: hidden;
}
.carousel .carousel-indicators li {
  width: 30px;
  height: 3px;
  opacity: 1;
  transition: 0.3s ease all;
  cursor: pointer;
}
.carousel .carousel-indicators li.active,
.carousel .carousel-indicators li.slick-active {
  background: #fff;
}
.carousel .carousel-indicators li:hover {
  background: rgba(255,255,255,0.8);
}
.carousel .carousel-control-next,
.carousel .carousel-control-prev {
  opacity: 0.4;
  transition: 0.3s ease opacity;
  z-index: 2;
}
.carousel .carousel-control-next.slick-disabled,
.carousel .carousel-control-prev.slick-disabled,
.carousel .carousel-control-next.disabled,
.carousel .carousel-control-prev.disabled {
  opacity: 0.2 !important;
  cursor: default;
}
.carousel .carousel-control-next:focus,
.carousel .carousel-control-prev:focus {
  opacity: 0.6;
}
.carousel .carousel-control-next:hover,
.carousel .carousel-control-prev:hover {
  opacity: 1;
}
.carousel .carousel-control-next .carousel-control-next-icon,
.carousel .carousel-control-prev .carousel-control-next-icon,
.carousel .carousel-control-next .carousel-control-prev-icon,
.carousel .carousel-control-prev .carousel-control-prev-icon {
  width: 20px;
  height: 20px;
}
.component-carousel {
  padding-top: 0;
  padding-bottom: 0;
}
.carousel .carousel-control-next,
.carousel .carousel-control-prev {
  opacity: 1;
}
.carousel .carousel-control-next .fa,
.carousel .carousel-control-prev .fa {
  font-size: 50px;
}
@media only screen and (min-width: 992px) {
  .carousel .carousel-inner .carousel-item {
    height: 400px !important;
  }
}
.carousel .carousel-inner .carousel-item img {
  width: 1170px !important;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.carousel .carousel-inner .carousel-item img.position-absolute {
  top: 0;
  z-index: 0;
  width: 120% !important;
  filter: blur(8px);
  max-width: 120%;
  transform: translate(-10%, -10%);
}
.carousel .carousel-inner .carousel-item:before {
  width: 100%;
  height: 35%;
  content: ' ';
  position: absolute;
  bottom: 0;
  z-index: 2;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0, rgba(0,0,0,0.5) 100%);
}
.carousel .carousel-caption h3 {
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 38px;
  font-weight: 400;
  text-shadow: 2px 1px 2px rgba(0,0,0,0.51);
}
.carousel .carousel-caption h1 {
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 48px;
  font-weight: 700;
  text-shadow: 2px 1px 2px rgba(0,0,0,0.51);
}
.carousel .carousel-caption h5 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-shadow: 2px 1px 2px rgba(0,0,0,0.51);
}
.carousel .carousel-caption .btn {
  width: 180px;
  margin: 15px 0;
}
.carousel .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 25px;
  background-color: #fff;
  border: 1px solid #fff;
}
.carousel .carousel-indicators li.active {
  background-color: #ed1c24;
}
.slider-mode-text .slick-slide {
  padding: 1em 4em;
  text-align: center;
}
.slider-mode-text .carousel-indicators {
  bottom: -40px;
}
.slider-mode-text .carousel-control-prev {
  left: -8%;
}
.slider-mode-text .carousel-control-next {
  right: -8%;
}
@media only screen and (max-width: 768px) {
  .slider-mode-text .carousel-control-prev {
    left: 0%;
  }
  .slider-mode-text .carousel-control-next {
    right: 0%;
  }
}
/* Cards (Portfolio / Products) */
.card .card-thumbnail {
  height: 120px;
  overflow: hidden;
  position: relative;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card .card-thumbnail .card-img-top {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.card .card-thumbnail a.card-image {
  display: block;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.card .card-body p {
  margin-bottom: 0.5rem;
}
.card .card-body .card-price {
  line-height: 1;
}
.card .card-body .card-price .badge-promo {
  float: right;
}
.card .card-body .card-text {
  font-size: 0.8rem;
  height: 60px;
  overflow: hidden;
}
.no-transition {
  -webkit-transition: height 0.01s;
  -moz-transition: height 0.01s;
  -ms-transition: height 0.01s;
  -o-transition: height 0.01s;
  transition: height 0.01s;
}
.product-card.main-category-card {
  min-height: auto !important;
}
.product-card.main-category-card .card-header .card-title {
  height: inherit;
}
.product-card.main-category-card .card-footer {
  margin-top: 20px;
}
.product-card .breadcrumbs {
  font-size: 14px;
}
.product-card .breadcrumbs .list-inline-item {
  padding-right: 0.5rem;
  display: inline;
}
.product-card .breadcrumbs .list-inline-item a {
  display: inline;
}
.product-card .breadcrumbs .breadcrumb-item+.breadcrumb-item::before {
  padding-left: 0;
}
.product-card .breadcrumbs .breadcrumb-root {
  font-weight: bold;
  display: block;
}
#product-form td {
  display: inline-block;
}
#product-form #product-quantity {
  margin-left: -1px;
}
.list-tax .children {
  padding-left: 24px;
  list-style: none;
}
.list-tax .custom-control {
  display: inline-block;
}
.list-group-item {
  padding: 0.5rem 1.5rem;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.custom-control-input:checked~.custom-control-label::before {
  background: #ed1c24;
}
.pagination .page-item {
  background: #fff;
  box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.04);
}
.pagination .page-item.disabled .page-link {
  color: #ccc;
}
.pagination .page-item .page-link {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700;
  border: none;
  color: #3e0000;
  padding: 0.5rem 0.85rem;
}
.pagination .page-item .page-link.dots {
  color: #3e0000 !important;
  cursor: default;
  background: #fff !important;
}
.pagination .page-item .page-link:hover {
  background-color: rgba(237,28,36,0.2);
  color: #ed1c24;
}
.pagination .page-item .page-link:focus {
  outline: none;
  box-shadow: none;
}
.page-item.active .page-link {
  background-color: #ed1c24;
  border-color: #ed1c24;
  color: #fff;
}
.page-item.active .page-link:hover {
  background-color: #ed1c24;
  border-color: #ed1c24;
  color: #fff;
}
.store-map {
  width: 100%;
  height: 250px;
  border: none;
}
.list-tax-product-categories a {
  color: #3e0000;
  font-size: 11px;
  cursor: default;
}
.list-tax-product-categories > .cat-item > ul.children {
  padding-top: 4px;
  padding-bottom: 6px;
}
.list-tax-product-categories > li {
  padding: 10px 15px;
  border-bottom: 1px solid #f0f0f0;
  position: relative;
/*
		> .custom-checkbox {
			display: none;
		}
		*/
}
.list-tax-product-categories > li.cat-item-71 > a:after,
.list-tax-product-categories > li.cat-item-72 > a:after,
.list-tax-product-categories > li.cat-item-82 > a:after {
  display: none !important;
}
.list-tax-product-categories > li.no-children a:after {
  display: none;
}
.list-tax-product-categories > li > a {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}
.list-tax-product-categories > li > a:after {
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 22px;
  right: 15px;
  transform: translateY(-50%);
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\f054";
  font-size: 12px;
}
.list-tax-product-categories > li > a.open:after {
  content: "\f078";
}
.list-tax-product-categories > li > .children {
  display: none;
}
.list-tax-product-categories > li .children {
  padding-left: 22px;
}
/* Modals */
.modal .modal-content {
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
}
.modal.user-modal .close {
  z-index: 2;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.custom-subnav a {
  font-family: $bold;
  display: block;
  background: #f2f2f2;
  transition: 0.3s ease all;
  padding: 20px;
  font-size: 17px;
  line-height: 1;
  margin-bottom: 1px;
}
.custom-subnav a.is-privacy {
  padding-left: 40px;
  font-family: $regular;
}
.custom-subnav a.active,
.custom-subnav a.active:hover {
  background: #ed1c24;
  color: #fff;
}
.custom-subnav a:hover {
  color: #f2f2f2;
}
.page .faq-list li {
  margin: 0px -15px;
  padding: 5px 15px;
  border-bottom: 1px solid #eee;
}
.page .faq-list li:first-child {
  border-top: 1px solid #eee;
}
#shop.page {
  background: #f6f6f6;
}
.component-home-new-products .spaced,
.component-home-popular-products .spaced,
.component-our-stores .spaced {
  padding-top: 25px;
  padding-bottom: 15px;
}
.products-area-heading {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700;
  font-size: 48px;
  color: #3e0000;
}
.products-area-heading span {
  font-weight: 400;
  color: #ed1c24;
}
.products-results .card,
.component-home-popular-products .card,
.component-home-new-products .card {
  min-height: 356px;
}
.products-results .card.product-split-card,
.component-home-popular-products .card.product-split-card,
.component-home-new-products .card.product-split-card {
  min-height: 324px;
}
.more-options {
  position: absolute;
  margin: 0px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 140px;
  text-align: center;
  background: #eee;
  color: #666;
  font-size: 12px;
  font-weight: 600;
}
.more-options a {
  color: #666;
}
#shop.page .card {
  box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.04);
}
.card {
  border: none;
  box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.1);
}
.card .card-header {
  padding: 15px;
  border-bottom: none;
}
.card .card-header .card-title {
  color: #ed1c24;
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 24px;
  height: 56px;
  overflow: hidden;
}
.card .card-thumbnail {
  padding-left: 15px;
  padding-right: 15px;
}
.card .card-thumbnail .card-img-top {
  position: relative;
}
.card .card-body {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 0;
}
.card .card-body .card-text {
  height: fit-content;
}
.card .card-body .badges span {
  font-size: 12px;
}
.card .card-body .badges .category {
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #3e0000;
}
.card .card-body .variant-wrapper {
  padding-top: 5px;
  flex-wrap: wrap;
}
.card .card-body .variant-wrapper .variant {
  margin: 5px 0;
  border: 1px solid #c6c6c6;
  margin-right: 5px;
  text-align: center;
  color: #3e0000;
  padding: 3px 3px;
  font-weight: 700;
  border-radius: 3px;
}
.card .card-body .variant-wrapper .variant .weight {
  font-size: 11px;
}
.card .card-body .variant-wrapper .variant .price {
  font-size: 12px;
  font-weight: 700;
}
.card .card-body .card-price {
  font-weight: 700;
  font-size: 16px;
}
.card .card-footer {
  border-top: none;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0;
}
.card .card-footer a {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}
.product-controls label strong {
  font-weight: 400;
  font-size: 12px;
}
.variant-wrapper {
  padding: 5px 0 10px 0;
  flex-wrap: wrap;
}
.variant-wrapper span {
  font-size: 12px;
}
.variant-wrapper a {
  display: block;
  width: 10%;
}
.variant-wrapper a .variant {
  border: 1px solid #c6c6c6;
  margin-right: 7px;
  text-align: center;
  color: #3e0000;
  padding: 3px 0;
  font-weight: 700;
  border-radius: 3px;
}
.variant-wrapper a .variant.active {
  background-color: #ed1c24;
}
.variant-wrapper a .variant.active p {
  color: #fff;
}
.variant-wrapper a .variant .weight {
  font-size: 11px;
}
.variant-wrapper a .variant .price {
  font-size: 12px;
  font-weight: 700;
}
.variant-wrapper a.active .variant {
  background-color: #ed1c24;
  color: #fff;
}
.store-name {
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #3e0000;
}
.address-details {
  margin-top: 5px;
}
.address-details.email {
  padding-top: 15px;
}
.address-details div {
  color: #3e0000;
  font-size: 12px;
}
.address-details div i {
  color: #ed1c24;
}
.sub-bar {
  min-height: 60px;
  margin-top: 12px;
}
.sub-bar p {
  color: #fff;
  font-size: 14px;
}
.sub-bar p span {
  font-weight: 700;
}
.sub-bar input {
  height: 35px;
  font-size: 13px;
  color: #3e0000;
  font-family: 'Open Sans', sans-serif;
  padding: 0px 15px;
  border: 1px solid #c6c6c6;
  border-radius: 3px;
}
.sub-bar input:placeholder {
  color: #c6c6c6;
}
.sub-bar .name {
  width: 42%;
  margin-right: 10px;
}
.sub-bar .email {
  width: 54.8%;
}
/* -------------------------------------------------------------------------------
    Style Bootstrap Classes
   ------------------------------------------------------------------------------- */
.btn,
a.btn,
input.btn,
button.btn {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-weight: 700;
  min-height: 35px;
  text-transform: uppercase;
}
.btn:focus,
a.btn:focus,
input.btn:focus,
button.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.btn.btn-primary,
a.btn.btn-primary,
input.btn.btn-primary,
button.btn.btn-primary {
  background-color: #ed1c24;
  border: 1px solid #ed1c24;
  color: #fff;
}
.btn.btn-primary:hover,
a.btn.btn-primary:hover,
input.btn.btn-primary:hover,
button.btn.btn-primary:hover {
  background-color: #fff;
  color: #ed1c24;
}
.btn.btn-primary:active,
a.btn.btn-primary:active,
input.btn.btn-primary:active,
button.btn.btn-primary:active {
  background-color: #fff !important;
  color: #ed1c24 !important;
  border-color: #ed1c24 !important;
}
.btn.btn-secondary,
a.btn.btn-secondary,
input.btn.btn-secondary,
button.btn.btn-secondary {
  background-color: #333;
  border: 1px solid #333;
  color: #fff;
}
.btn.btn-secondary:hover,
a.btn.btn-secondary:hover,
input.btn.btn-secondary:hover,
button.btn.btn-secondary:hover {
  background-color: #f5f5f5;
  color: #333;
}
.btn.btn-secondary:active,
a.btn.btn-secondary:active,
input.btn.btn-secondary:active,
button.btn.btn-secondary:active {
  background-color: #f5f5f5 !important;
  color: #333 !important;
  border-color: #333 !important;
}
.btn.btn-success:hover,
a.btn.btn-success:hover,
input.btn.btn-success:hover,
button.btn.btn-success:hover {
  background: #fff !important;
  color: #28a745 !important;
}
.btn.btn-success:active,
a.btn.btn-success:active,
input.btn.btn-success:active,
button.btn.btn-success:active {
  background-color: #fff !important;
  color: #28a745 !important;
  border-color: #28a745 !important;
}
.btn.btn-outline-dark:hover,
a.btn.btn-outline-dark:hover,
input.btn.btn-outline-dark:hover,
button.btn.btn-outline-dark:hover {
  background-color: #333;
  border: 1px solid #333;
  color: #fff;
}
.btn.btn-light,
a.btn.btn-light,
input.btn.btn-light,
button.btn.btn-light {
  background-color: #f3f3f3;
  color: #3c3c3c;
}
.btn.btn-light:active,
a.btn.btn-light:active,
input.btn.btn-light:active,
button.btn.btn-light:active {
  background-color: #f3f3f3 !important;
  color: #3c3c3c !important;
  border-color: #3c3c3c !important;
}
.col-login {
  max-width: 28rem;
}
@media only screen and (min-width: 992px) {
  #nav {
    height: 60px;
  }
}
#navbar {
  justify-content: flex-end;
}
.search-and-login {
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 290px;
  margin-left: auto;
  margin-top: -70px;
}
.search-and-login .socials-and-account {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.search-and-login .socials-and-account .nav-link {
  font-size: 14px;
  color: #3e0000;
  font-weight: 700;
  font-family: 'PT Sans Narrow', sans-serif;
}
.logo-area {
  padding-top: 14px;
}
.logo-area .navbar-brand {
  width: 130px;
}
.logo-area .navbar-brand img {
  width: 100%;
}
.main-navigation {
  width: 100%;
}
.main-navigation .nav {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.navbar {
  backface-visibility: hidden;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-weight: 400;
}
.navbar li.nav-item:first-child {
  padding-left: 0;
}
.navbar li.nav-item:first-child a.nav-link {
  padding-left: 0px;
}
.navbar a.nav-link {
  color: rgba(0,0,0,0.5);
}
.navbar a.nav-link:focus,
.navbar a.nav-link:active,
.navbar a.nav-link:hover,
.navbar a.nav-link.active {
  color: #000;
}
.navbar .dropdown-menu {
  margin-top: 0 !important;
}
.navbar .dropdown-menu li {
  width: 100%;
  margin-left: 0 !important;
}
.navbar .dropdown-account {
  position: absolute;
  top: 30px;
  left: auto;
  right: -10px;
  border: none;
  width: 100%;
  min-width: inherit;
  border-radius: 0;
  font-size: 80%;
  right: 0;
  top: 21px;
  margin-top: 0;
}
.navbar.subnav {
  top: 60px;
  z-index: 1020;
  min-height: 55px;
}
.navbar.bg-dark li.nav-item a.nav-link {
  color: rgba(255,255,255,0.5);
}
.navbar.bg-dark li.nav-item a.nav-link:focus,
.navbar.bg-dark li.nav-item a.nav-link:active,
.navbar.bg-dark li.nav-item a.nav-link:hover,
.navbar.bg-dark li.nav-item a.nav-link.active {
  color: #fff;
}
.contacts .container div {
  height: 20px;
  font-size: 11px;
  margin-left: 10px;
  color: #888;
}
.contacts .container div a {
  color: #888;
}
#nav {
  height: 85px;
}
#nav .nav-link {
  font-family: 'PT Sans Narrow', sans-serif;
  color: #3e0000;
  font-size: 1.125rem;
  letter-spacing: 0.2px;
}
#nav .nav-link:hover {
  color: #ed1c24;
}
#nav .acc-menu.show {
  background-color: #ed1c24;
}
#nav .acc-menu.show a {
  color: #fff;
}
#nav .acc-menu.show .dropdown-account a {
  color: #3e0000;
  font-weight: 700;
}
#nav #search-header {
  margin-top: 5px;
}
#nav-seconday {
  height: 40px;
}
#nav-seconday #navbar {
  position: relative;
}
#nav-seconday #navbar .nav-item .dropdown-toggle + .dropdown-menu {
  display: none;
}
#nav-seconday #navbar .nav-item:hover .dropdown-toggle + .dropdown-menu,
#nav-seconday #navbar .nav-item:hover .dropdown-toggle + .dropdown-menu:hover {
  display: flex !important;
}
#nav-seconday .navbar-nav {
  height: 40px;
  border-right: 1px solid rgba(246,246,246,0.4);
}
#nav-seconday .navbar-nav >li {
  height: 100%;
  border-left: 1px solid rgba(246,246,246,0.4);
}
#nav-seconday .navbar-nav >li.nav-item {
  cursor: default;
  padding: 0 0.3em;
}
#nav-seconday .navbar-nav >li.nav-item:first-child {
  padding-left: 10px;
}
#nav-seconday .navbar-nav >li.nav-item:first-child a.nav-link {
  padding-left: 0.5rem;
}
#nav-seconday .nav-link {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}
#nav-seconday .nav-link:hover {
  color: #d3d3d3;
}
#nav-seconday #menu-products-navigation-1 li:nth-child(1) .dropdown-menu::before {
  margin-left: 4.7em;
}
#nav-seconday #menu-products-navigation-1 li:nth-child(2) .dropdown-menu::before {
  margin-left: 12.1em;
}
#nav-seconday #menu-products-navigation-1 li:nth-child(3) .dropdown-menu::before {
  margin-left: 18.6em;
}
#nav-seconday #menu-products-navigation-1 li:nth-child(4) .dropdown-menu::before {
  margin-left: 25.6em;
}
#nav-seconday #menu-products-navigation-1 li:nth-child(5) .dropdown-menu::before {
  margin-left: 31.4em;
}
#nav-seconday #menu-products-navigation-1 li:nth-child(6) .dropdown-menu::before {
  margin-left: 35.9em;
}
#nav-seconday #menu-products-navigation-1 li:nth-child(7) .dropdown-menu::before {
  margin-left: 40.4em;
}
#nav-seconday #menu-products-navigation-1 li:nth-child(8) .dropdown-menu::before {
  margin-left: 48.7em;
}
#nav-seconday #menu-products-navigation-1 li:nth-child(9) .dropdown-menu::before {
  margin-left: 56.4em;
}
#nav-seconday #menu-products-navigation-1 li:nth-child(10) .dropdown-menu::before {
  margin-left: 65.6em;
}
#nav-seconday #menu-products-navigation-1 {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
}
#nav-seconday .nav-item-339 {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  box-ordinal-group: 1;
}
#nav-seconday .nav-item-249,
#nav-seconday .nav-item-271,
#nav-seconday .nav-item-282,
#nav-seconday .nav-item-231,
#nav-seconday .nav-item-283,
#nav-seconday .nav-item-294,
#nav-seconday .nav-item-262,
#nav-seconday .nav-item-272,
#nav-seconday .nav-item-273 {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  box-ordinal-group: 2;
}
#nav-seconday .parent-nav .dropdown-toggle::after {
  content: none;
}
#nav-seconday .parent-nav .dropdown-menu {
  width: 100%;
  margin-top: 0;
  padding: 0;
  display: none;
  border-radius: 0;
  border: none;
  z-index: 1111111;
}
#nav-seconday .parent-nav .dropdown-menu.show {
  display: flex;
}
#nav-seconday .parent-nav .dropdown-menu::before {
  position: absolute;
  transform: rotate(180deg) scale(1.5);
  color: #fff;
  top: -6px;
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
#nav-seconday .parent-nav .dropdown-menu > div {
  width: 100%;
  column-count: 4;
  column-gap: 4em;
  padding: 25px;
}
#nav-seconday .parent-nav .dropdown-menu > div h6 {
  color: #ed1c24;
  font-weight: 700;
  margin-bottom: 4px;
  padding-top: 0;
  font-size: 12px;
  text-transform: uppercase;
}
#nav-seconday .parent-nav .dropdown-menu > div h6 a {
  font-size: 12px;
}
#nav-seconday .parent-nav .dropdown-menu > div ul {
  width: 100%;
  margin-bottom: 12px;
}
#nav-seconday .parent-nav .dropdown-menu > div ul li {
  font-size: 12px;
  color: #3e0000;
}
#nav-seconday .parent-nav .dropdown-menu > div ul li a {
  color: #3e0000;
}
.social {
  display: block;
  grid-template-columns: auto auto;
}
.social span {
  background-color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  margin: 0 5px;
  float: left;
}
.social span i {
  color: #ed1c24;
}
.payfast-logo {
  margin-top: -46px;
}
.submit-btn {
  position: absolute;
  margin-left: -30px;
  margin-top: 5px;
  background: none;
  border: none;
}
.submit-btn .search-submit {
  position: static;
  margin-left: 0;
  margin-top: 0;
}
/* -------------------------------------------------------------------------------
    Footer
   ------------------------------------------------------------------------------- */
#footer {
  margin-top: 0;
  padding: 40px 0px;
  min-height: 250px;
  font-size: 90%;
  -webkit-font-smoothing: subpixel-antialiased;
}
#footer li {
  margin-bottom: 5px;
}
#footer .fab {
  color: #666;
  margin-right: 5px;
}
.footer-wrapper {
  display: grid;
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 2fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr);
}
.footer-wrapper h6 {
  color: #f6f6f6;
  font-size: 12px;
  font-weight: 700;
}
.footer-wrapper .contact-info .contact-details {
  display: flex;
  flex-wrap: wrap;
}
.footer-wrapper .contact-info .contact-details .email {
  margin-top: 10px;
  margin-bottom: 18px;
}
.footer-wrapper .contact-info .contact-details .email a {
  font-size: 12px;
  color: #888;
}
.footer-wrapper .contact-info .contact-details div {
  margin-top: 1px;
}
.footer-wrapper .contact-info .contact-details .label {
  width: 30%;
  font-size: 12px;
  font-weight: 700;
  color: #c6c6c6;
}
.footer-wrapper .contact-info .contact-details .value {
  width: 60%;
  font-size: 12px;
  color: #888;
}
.footer-wrapper .contact-info .contact-details .value a {
  color: #888;
}
.footer-wrapper .brand-footer .copyrights {
  font-size: 11px;
  color: #888;
  margin-top: 25px;
}
.footer-wrapper .brand-footer .copyrights span {
  font-weight: 700;
  color: #c6c6c6;
}
.footer-wrapper .sitemap h6 {
  margin-bottom: 18px;
}
.footer-wrapper .sitemap ul li {
  padding-left: 0;
  margin-bottom: 0 !important;
}
.footer-wrapper .sitemap ul li a {
  padding-left: 0;
  font-size: 12px;
  color: #888;
}
.footer-wrapper .account h6,
.footer-wrapper .legal h6 {
  margin-bottom: 18px;
}
.footer-wrapper .account ul li,
.footer-wrapper .legal ul li {
  margin-bottom: 0 !important;
}
.footer-wrapper .account ul li a,
.footer-wrapper .legal ul li a {
  font-size: 12px;
  color: #888;
}
/* -------------------------------------------------------------------------------
    Forms
   ------------------------------------------------------------------------------- */
label {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
input,
select,
textarea {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-weight: 400;
}
.custom-control-label,
.custom-control-label a {
  vertical-align: top !important;
}
.custom-control-label.small,
.custom-control-label a.small {
  line-height: 1.6;
}
.custom-select {
  padding: 0.375rem 0.75rem;
  padding-right: 30px;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px #fff;
}
form.ajax button.btn-primary[type="submit"].ajax-loading,
a.btn-dark.ajax-loading {
  text-indent: -9999px;
  background-image: url("../img/loading-white.svg");
  background-position: center center;
  background-repeat: no-repeat;
}
.search-input {
  background: #fff;
  border-radius: 3px;
  border: none !important;
  box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.1);
  padding: 0.5rem 0.85rem;
  line-height: 1.25;
}
.search-submit {
  position: absolute;
  margin-left: -30px;
  margin-top: 5px;
}
.form-row {
  margin-bottom: 1rem;
}
.result {
  min-height: 1px;
  font-size: 12px;
}
.result :first-child {
  margin-top: 0;
  padding-top: 0;
}
.success-msg {
  color: #2ea300;
}
.error-msg,
.parsley-errors-list {
  color: #a30000;
  font-size: 12px;
}
.parsley-errors-list {
  margin: 0 0 0 0;
  padding: 0;
  list-style: none;
  display: block;
  overflow: hidden;
}
.parsley-error,
:focus.parsley-error {
  border-color: #a30000;
}
:focus.parsley-error {
  box-shadow: 0 0 0 2px rgba(163,0,0,0.25);
}
.form-control::-webkit-input-placeholder {
  color: #999;
}
.form-control:-moz-placeholder {
  color: #999;
}
.form-control::-moz-placeholder {
  color: #999;
}
.form-control:-ms-input-placeholder {
  color: #999;
}
.form-control.placeholder {
  color: #999;
}
/* -------------------------------------------------------------------------------
    Page Specific
   ------------------------------------------------------------------------------- */
/* Contact */
.contact-form .contact-msg {
  height: calc(100% - 32px);
}
.contact-form h2 {
  font-size: 48px;
  font-family: 'PT Sans Narrow', sans-serif;
  color: #3e0000;
}
.contact-form h2 span {
  color: #ed1c24;
  font-weight: 400;
}
.contact-form label {
  font-size: 13px;
  color: #888;
}
.contact-form label sup {
  color: #ed1c24;
  font-size: 16px;
  position: relative;
  top: 5px;
}
.contact-form input::placeholder,
.contact-form textarea::placeholder {
  font-size: 12px;
}
.contact-form .btn {
  width: 155px;
  height: 35px;
}
/* Products */
.products-results:before {
  transition: opacity 0.5s ease;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: url("../img/loading.svg") no-repeat center 100px rgba(246,246,246,0.7);
  opacity: 0;
  pointer-events: none;
}
.products-results.loading:before {
  opacity: 1;
}
.price-new {
  font-weight: bold;
}
.price-old {
  text-decoration: line-through;
}
.product-price {
  line-height: 1;
}
.container-product .no-stock-hide {
  display: block;
}
.container-product .no-stock-hide.no-stock {
  display: none;
}
.container-product tr.no-stock-hide {
  display: table-row;
}
.container-product tr.no-stock-hide.no-stock {
  display: none;
}
.container-product .no-stock-row {
  display: none;
}
.container-product .no-stock-row.no-stock {
  display: block;
}
.container-product tr.no-stock-hide {
  display: table-row;
}
.container-product tr.no-stock-hide.in-store {
  display: none;
}
.container-product .in-store-row {
  display: none;
}
.container-product .in-store-row.in-store {
  display: block;
}
.container-product .breadcrumb {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  background: transparent;
}
.breadcrumb-item+.breadcrumb-item::before {
  content: '>';
}
/* Checkout */
#checkout .checkout-step .btn.btn-primary {
  background-color: #28a745 !important;
  color: #fff !important;
  border-color: #28a745 !important;
}
#checkout .checkout-step .btn.btn-primary:hover {
  background: #fff !important;
  color: #28a745 !important;
}
#checkout .checkout-step .btn.btn-primary:active {
  background-color: #fff !important;
  color: #28a745 !important;
  border-color: #28a745 !important;
}
#checkout .checkout-btn-group .btn {
  text-transform: none;
  letter-spacing: 0;
}
#checkout .checkout-btn-group button {
  border: none;
}
#checkout .checkout-btn-group button:after {
  content: "";
  width: 12px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
}
#checkout .form-footer {
  display: none;
}
#checkout .checkout-step {
  display: none;
}
#checkout .checkout-step.active {
  display: block;
}
.checkout-cart .cart-item-qty {
  padding: 0;
}
.checkout-cart .cart-item-qty-group {
  width: 90px;
}
#voucher-error {
  display: none;
  margin-top: 3px;
  color: #a30000;
}
#voucher-error.show {
  display: block;
}
.cart-count {
  margin-left: 1px;
  margin-right: 1px;
}
table.table img {
  max-width: inherit;
}
#profile-list#profile-list .active {
  background-color: #ffe1e1;
}
#profile-list a {
  color: #3e0000;
}
/*  --------------------------------------------------------------------
    SHOP
    -------------------------------------------------------------------- */
.page-title {
  font-size: 42px;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
}
.page-title span.font-weight-normal {
  font-size: 29px;
}
.products-found {
  font-size: 32px;
  font-family: 'PT Sans Narrow', sans-serif;
  color: #ed1c24;
}
.products-found strong,
.products-found span {
  color: #3e0000;
}
.categories .filter-head {
  padding: 10px 15px 10px 15px;
  background-color: #3e0000;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
}
.categories .card-body {
  padding-top: 0;
}
.categories .card-body.card {
  max-height: fit-content;
  min-height: fit-content;
  padding-top: 15px;
  border-bottom: 1px solid #3e0000;
  border-radius: 0;
}
.categories .cat-title {
  padding: 10px 15px;
  border-bottom: 1px solid #3e0000;
  font-size: 14px;
  color: #3e0000;
  font-weight: 700;
}
.categories .cat-title:first-child {
  border-top: 1px solid #3e0000;
}
.categories .cat-title i {
  transform: rotate(90deg);
  color: #3c3c3c;
  transition: all 0.3s;
}
.categories .cat-title.collapsed i {
  transform: rotate(0deg);
  transition: all 0.3s;
}
/*  --------------------------------------------------------------------
    PRODUCT VIEW
    -------------------------------------------------------------------- */
.container-product .img-fluid {
  min-width: 100%;
}
.container-product .product-code {
  color: #888;
  font-size: 12px;
}
.container-product .social-icons {
  position: absolute;
  right: 20px;
  margin-top: 10px;
}
.container-product .social-icons i {
  color: #ed1c24;
  font-size: 18px;
  transition: 0.3s ease all;
}
.container-product .social-icons a:hover i {
  color: #3e0000;
}
.container-product .product-content {
  font-size: 13px;
}
.container-product .price {
  color: #333;
  font-size: 48px;
}
.container-product .breadcrumbs .breadcrumb-item+.breadcrumb-item::before {
  content: '>';
  color: #ccc;
}
.container-product .breadcrumbs .breadcrumb-item a {
  color: #3e0000;
  font-size: 13px;
  font-weight: bold;
}
.container-product .breadcrumbs .breadcrumb-item:first-child a {
  color: #ed1c24;
}
.container-product .breadcrumbs .breadcrumb-item:last-child a {
  font-weight: normal;
}
.container-product .wishlist-btn {
  background-color: #c6c6c6;
  color: #fff;
}
.container-product .product-vars {
  flex-wrap: wrap;
}
.container-product .product-vars .product-var {
  border: 1px solid #ccc;
  padding: 3px 12px;
  border-radius: 3px;
  text-align: center;
  margin-right: 5px;
  color: #3e0000;
  margin-bottom: 5px;
}
.container-product .product-vars .product-var.selected {
  background: #ed1c24;
  color: #fff;
}
.container-product .product-vars .product-var .product-var-head,
.container-product .product-vars .product-var .product-var-qty,
.container-product .product-vars .product-var .product-var-size {
  display: flex;
  text-align: center;
  justify-content: center;
}
.container-product .product-vars .product-var .product-var-head {
  font-weight: bold;
  font-size: 11px;
}
.container-product .product-vars .product-var .product-var-qty {
  font-size: 15px;
}
.container-product .product-vars .product-var .product-var-size {
  font-size: 15px;
}
.container-product .variations-table {
  width: auto;
  margin-top: 20px;
}
.container-product .variations-table td,
.container-product .variations-table th {
  vertical-align: top;
  padding: 5px 15px 5px 0;
  border: none;
}
.container-product .variations-table td:first-child,
.container-product .variations-table th:first-child {
  min-width: 150px;
}
.container-product .product-variation-image-container {
  border: #ccc;
  box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.1);
}
.container-product .product-variation-image {
  height: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.container-product #product-quantity {
  height: 30px;
  line-height: 26px;
  padding-top: 0;
  padding-bottom: 0;
  border-color: #ccc;
  color: #3e0000;
}
.breadcrumb-btn {
  background-color: #c6c6c6 !important;
  width: 125px !important;
  min-height: 25px !important;
  max-height: 25px !important;
  padding: 0 !important;
  color: #fff !important;
  text-transform: uppercase;
  font-size: 12px;
}
.breadcrumb-btn:hover {
  background-color: #ed1c24 !important;
}
/*  --------------------------------------------------------------------
    CHECKOUT
    -------------------------------------------------------------------- */
/*
.btn-checkout.btn-success {
	background-color: #28a745 !important;
	border-color: #28a745 !important;
	color: #fff !important;
	transition: 0.3s ease all;

	&:active, &:focus {
		background-color: #28a745 !important;
		border-color: #28a745 !important;
		color: #fff !important;
	}

	&:hover {
		opacity: 0.85;
	}
}
*/
.checkout-cart .product-name {
  color: #3e0000;
  font-size: 14px;
  text-decoration: underline;
  white-space: normal;
}
.checkout-cart th {
  color: #3c3c3c;
  font-weight: 700;
  font-size: 16px;
  text-transform: none;
}
.checkout-cart .cart-item-qty-group .btn-cart-update {
  border: none;
}
.checkout-cart .cart-item-qty-group input {
  border: none;
  background-color: transparent;
}
.checkout-cart td {
  font-size: 14px;
}
.checkout-cart .is-editable .remove-btn {
  background-color: none;
  border: none;
  text-decoration: underline;
}
.help {
  border: 1px solid #c6c6c6;
}
.help .contact-help {
  font-size: 16px;
  color: #3e0000;
}
.help .contact-help a {
  color: #3e0000;
}
.help .contact-help i {
  color: #ed1c24;
}
.help h5 {
  font-weight: 700;
  font-size: 16px;
}
.help ul li a {
  font-size: 12px;
  color: #3e0000;
  text-decoration: underline;
}
.help .view-more {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 15px;
  display: inline-block;
}
/*
.btn-checkout {

    &:active {
        background-color: #fff !important;
        color: $primary !important;
        border-color: $primary !important;
    }

	&.btn-primary:hover {
		color: #fff !important;
		background-color: $primary !important;
	}
}
*/
/*  --------------------------------------------------------------------
    MODALS
    -------------------------------------------------------------------- */
.modal-content .card-title {
  font-size: 34px;
}
.modal-content label {
  font-size: 13px;
  color: #888;
}
.modal-content label span {
  color: #ed1c24;
}
.modal-content .custom-checkbox span {
  color: #3e0000;
}
.modal-content .custom-checkbox span a {
  color: #3e0000;
}
.modal-content .custom-checkbox span a span {
  color: #ed1c24;
}
.modal-content .text-muted {
  color: #3e0000 !important;
}
.modal-content .text-muted a {
  color: #ed1c24;
}
.modal-content .forgot-pass {
  color: #ed1c24;
}
/*  --------------------------------------------------------------------
    404
    -------------------------------------------------------------------- */
.error-page h1 {
  font-size: 180px;
  color: #ed1c24;
  font-family: 'PT Sans Narrow', sans-serif;
}
.error-page p,
.error-page h4 {
  font-size: 44px;
  font-family: 'PT Sans Narrow', sans-serif;
}
.error-page h4 {
  font-weight: 700;
  margin-bottom: 20px;
}
@media (max-width: 1199px) {
  #nav-seconday .navbar-nav >li.nav-item {
    padding: 0 8px;
  }
  #nav-seconday .navbar-nav >li.nav-item:first-child {
    padding-left: 8px;
  }
  #nav-seconday .search {
    display: flex;
    align-items: center;
  }
  .sub-bar .email {
    width: 53.8%;
  }
}
@media (max-width: 991px) {
  #nav {
    height: 55px;
  }
  #nav #menu-main-navigation {
    padding: 10px 0 2px 15px;
  }
  #nav .navbar-nav .nav-item,
  #nav .navbar-nav .nav-link {
    padding-left: 0;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    padding-bottom: 5px;
  }
  #nav .navbar-nav >li {
    justify-content: center;
  }
  #nav .navbar-nav .link-wrapper {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  #nav .navbar-nav .link-wrapper .menu-item {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  #nav #navbar >.nav-link,
  #nav #navbar .checkout-link {
    justify-content: center;
    padding-bottom: 5px;
  }
  #nav #navbar .acc-menu {
    width: 100%;
  }
  #nav #navbar .acc-menu .nav-link {
    justify-content: center;
    padding: 0;
  }
  #nav #navbar .main-nav {
    padding-left: 0;
  }
  #nav #navbar .main-nav .nav-item {
    padding-right: 0;
  }
  #nav #menu-products-navigation {
    display: block;
    flex-direction: row;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 30px;
  }
  #nav #menu-products-navigation .link-wrapper {
    display: block;
    padding: 5px;
  }
  #nav #menu-products-navigation .link-wrapper .nav-link {
    display: block;
    width: 100%;
    text-align: center;
  }
  #nav #menu-products-navigation .link-wrapper div {
    padding: 5px 10px;
    border-right: 1px solid rgba(246,246,246,0.4);
  }
  #nav #menu-products-navigation .link-wrapper div:last-child {
    border-right: none;
  }
  #nav #menu-products-navigation .link-wrapper div a {
    color: #fff;
  }
  #nav #menu-products-navigation .link-wrapper .fa-search {
    color: #fff;
    transform: translateY(2px);
  }
  #nav #menu-products-navigation .link-wrapper input {
    background-color: transparent;
    border: none;
    width: 50px;
    padding-bottom: 5px;
    color: #fff;
  }
  #nav #menu-products-navigation .link-wrapper input::placeholder {
    color: #fff;
    font-size: 13px;
  }
  #nav .navbar-toggler {
    border: none;
  }
  #nav .navbar-toggler .fas {
    color: #3e0000;
  }
  #nav .navbar-toggler .navbar-toggler-icon {
    width: 1.1em;
    height: 1.1em;
  }
  #nav .navbar-toggler i.fa-bars:before {
    content: "\f00d";
  }
  #nav .navbar-toggler.collapsed i.fa-bars:before {
    content: "\f0c9";
  }
  #nav .navbar-brand img {
    width: 50px;
  }
  #nav .top-contact {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  #nav .top-contact div {
    text-align: center;
    font-size: 11px;
    color: #888;
  }
  #nav .top-contact div a {
    font-size: 11px;
    color: #888;
  }
  .navbar-collapse {
    background-color: #fff;
  }
  .collapse.show {
    z-index: 111;
    background-color: #fff;
  }
  .sub-bar .email {
    width: 50.8%;
  }
  .products-found {
    font-size: 22px;
  }
  .footer-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .footer-wrapper >div {
    width: 20%;
    margin-bottom: 20px;
  }
  .footer-wrapper >div.brand-footer {
    width: 25%;
  }
  .footer-wrapper >div.contact-info {
    width: 40%;
  }
  .footer-wrapper >div.sitemap {
    width: 15%;
  }
}
@media (max-width: 767px) {
  .breadcrumb {
    padding-left: 30px !important;
  }
  .product-title {
    margin-top: 30px;
  }
  .variations-table td {
    display: block;
    width: 100%;
  }
  .contacts {
    height: 35px;
  }
  .carousel .carousel-inner .carousel-item img {
    width: 130%;
    max-width: 130%;
  }
  .carousel .carousel-caption {
    left: 10px;
    right: auto;
    bottom: 30px;
  }
  .carousel .carousel-caption h3,
  .carousel .carousel-caption h1 {
    font-size: 24px;
    text-align: left;
    margin-bottom: 0px;
  }
  .carousel .carousel-caption h5 {
    font-size: 14px;
    text-align: left;
    font-weight: 400;
  }
  .carousel .carousel-caption .btn {
    width: 105px;
    height: 25px;
    font-size: 10px;
    padding: 8px;
    margin: 0px auto 5px 0;
    position: absolute;
    left: 0;
  }
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
  .navbar-brand {
    margin-right: 0;
  }
  .address-details {
    width: 70%;
    margin: 5px auto 15px auto;
  }
  .sub-bar {
    padding-top: 10px;
  }
  .sub-bar .email,
  .sub-bar .name {
    width: 100%;
  }
  .sub-bar .email {
    margin-top: 10px;
  }
  .sub-bar .social {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 10px 0;
  }
  #footer {
    padding-bottom: 0;
    padding-top: 20px;
  }
  .footer-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .footer-wrapper h6 {
    margin-bottom: 0 !important;
  }
  .footer-wrapper >div {
    width: 100% !important;
    margin-bottom: 20px;
  }
  .footer-wrapper >div.brand-footer {
    display: flex;
  }
  .footer-wrapper >div.brand-footer img {
    width: 65px;
    margin-right: 5px;
  }
  .footer-wrapper >div.brand-footer br {
    display: none;
  }
  .footer-wrapper >div.contact-info .contact-details .label,
  .footer-wrapper >div.contact-info .contact-details .email {
    margin-bottom: 1px;
    margin-top: 1px;
  }
  .footer-wrapper >div.sitemap,
  .footer-wrapper >div.account {
    display: none;
  }
  .footer-wrapper >div.legal ul {
    display: flex;
  }
  .footer-wrapper >div.legal ul li {
    padding-right: 5px;
  }
  .footer-wrapper >div.legal ul li::after {
    content: '|';
    color: #888;
    margin-left: 3px;
  }
  .footer-wrapper >div.legal ul li:last-child::after {
    content: none;
  }
}
@media (max-width: 360px) {
  #nav {
    padding-left: 2px;
    padding-right: 2px;
  }
  #nav .top-contact div {
    font-size: 9px;
  }
  #nav .top-contact div a {
    font-size: 10px;
  }
  .footer-wrapper .legal ul li a {
    font-size: 10px;
  }
  .footer-wrapper >div.brand-footer img {
    width: 50px;
  }
  .footer-wrapper >div.brand-footer .copyrights {
    font-size: 9px;
  }
  .cookie-bar .copy {
    padding-left: 10px;
    width: 80%;
  }
  .cookie-bar .cookie-close {
    position: absolute;
    right: 60px;
    top: 110px;
  }
}
/* -------------------------------------------------------------------------------
    Media Queries
   ------------------------------------------------------------------------------- */
@media only screen and (max-width: 992px) {
  .tablet-hide {
    display: none !important;
  }
  .account-drop-down {
    margin-left: 0px !important;
  }
  .desktop {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .mobi-hide {
    display: none !important;
  }
  #nav .active .nav-link {
    color: #e00030 !important;
  }
  .menu-item-type-custom {
    min-width: unset;
  }
  .checkout-link small {
    padding-bottom: 0px;
  }
  #menu-products-navigation {
    padding-top: 20px !important;
    margin-top: 10px;
  }
  #menu-products-navigation .link-wrapper .nav-link {
    text-transform: uppercase;
    font-size: 12px !important;
    padding-bottom: 10px;
  }
  #menu-products-navigation .fa-search {
    font-size: 18px;
  }
  #menu-products-navigation #search-header {
    margin-left: 26px;
  }
  #menu-products-navigation .search-input {
    width: 80px !important;
    box-shadow: none;
    padding-left: 5px;
  }
  #menu-products-navigation .social {
    margin-bottom: 10px;
    margin-top: 5px;
    justify-content: center;
    display: flex !important;
    align-items: center;
  }
  .dropdown-toggle {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 576px) {
  .address-details {
    width: 100%;
  }
  .carousel .carousel-inner .carousel-item {
    height: 250px;
  }
  .carousel .carousel-inner .carousel-item img {
    max-width: 200%;
  }
  .carousel-caption {
    padding-top: 0px;
    top: 20px;
  }
  .carousel-caption h1 {
    font-size: 54px !important;
    margin-top: -10px;
  }
  .carousel-caption h3 {
    font-size: 40px !important;
  }
  .carousel-caption h5 {
    font-size: 18px !important;
    width: 300px;
  }
  .font-weight-normal {
    padding-bottom: 10px;
  }
  .spaced {
    padding-top: 0px;
  }
  .help {
    margin-top: 30px;
  }
  .cart-table-item {
    min-width: 250px;
  }
  #nav .navbar-brand img {
    margin-left: 10px;
    width: 40px;
    margin-top: -10px;
  }
  #nav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    background: #fff;
  }
  #template-default .page,
  #template- .page,
  #template-legal .page,
  #template-faq .page {
    margin-top: 60px;
  }
  .page {
    margin-top: 20px;
  }
  .cookie-bar .copy {
    padding-left: 10px;
    width: 80%;
  }
  .cookie-bar .cookie-close {
    position: absolute;
    right: 60px;
    top: 110px;
  }
}
@media only screen and (max-width: 320px) {
  .product-card .card-thumbnail {
    height: 140px;
  }
  .carousel .carousel-inner .carousel-item {
    height: 250px;
  }
  .carousel .carousel-inner .carousel-item img {
    max-width: 240%;
  }
  #checkout .btn {
    margin-bottom: 10px;
  }
}
.hide {
  display: none;
}
@media (min-width: 768px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sub-bar .align-items-center {
    margin-top: 11px;
  }
  footer .brand-footer,
  footer .contact-info,
  footer .account,
  footer .sitemap,
  footer .legal {
    float: left;
  }
  footer .brand-footer {
    width: 18%;
  }
  footer .contact-info {
    width: 25%;
  }
  footer .account {
    width: 18%;
  }
  footer .sitemap {
    width: 18%;
  }
  footer .legal {
    width: 18%;
  }
}
/***************************************************
NEW PAGES COMPONENTS
***************************************************/
.basic-layout {
  font-family: 'PT Sans Narrow', sans-serif;
}
.basic-layout h6 {
  font-size: 1.28rem;
}
.basic-layout .no-shop-layout >p {
  display: none !important;
}
.basic-layout p:empty {
  display: none !important;
}
@media (max-width: 991px) {
  .basic-layout .navbar-nav {
    background: transparent !important;
  }
}
@media (max-width: 991px) {
  .basic-layout .navbar-nav .link-wrapper {
    display: none;
  }
}
@media (max-width: 991px) {
  .basic-layout .logo-area {
    text-align: left !important;
    padding-top: 0;
  }
  .basic-layout .logo-area img {
    width: 50px;
  }
}
@media (max-width: 991px) {
  .basic-layout .search-and-login {
    align-items: flex-start;
    margin-right: auto;
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .basic-layout .search-and-login .socials-and-account {
    justify-content: center;
  }
  .basic-layout .search-and-login .link-wrapper {
    display: none !important;
  }
  .basic-layout .search-and-login .link-wrapper.social {
    display: flex !important;
  }
  .basic-layout .search-and-login .navbar-nav {
    padding-top: 0 !important;
  }
  .basic-layout .search-and-login #menu-products-navigation {
    padding-bottom: 0;
  }
}
@media (max-width: 991px) {
  .basic-layout #nav {
    justify-content: space-between;
  }
  .basic-layout #nav #menu-main-navigation {
    display: block;
  }
}
.basic-layout .wp-block-button .wp-block-button__link {
  background-color: #333;
  border: 1px solid #333;
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-weight: 700;
  font-size: 0.8125rem;
}
.services-overview h2 {
  font-family: 'PT Sans Narrow', sans-serif;
  position: relative;
  padding-bottom: 10px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  color: #ed1c24;
  font-weight: 400;
  min-width: 250px;
  text-align: center;
  margin-bottom: 1.57rem;
}
.services-overview h2 strong {
  color: #3e0000;
}
.services-overview h2::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: #3e0000;
}
.services-overview h6 {
  margin-bottom: 12px;
}
.services-overview .wp-block-columns {
  gap: 30px;
}
.services-overview .wp-block-columns >p {
  display: none !important;
}
.services-overview .wp-block-column {
  margin-left: 0 !important;
}
.services-overview .wp-block-column p {
  margin-bottom: 30px;
}
.services-overview .wp-block-column figure {
  margin: 0 !important;
  width: 100%;
}
.services-overview .wp-block-column figure img {
  width: 100%;
}
.services-overview .wp-block-buttons {
  text-align: center;
}
.services-overview .wp-block-buttons p {
  display: none !important;
}
.services-overview .wp-block-buttons .wp-block-button__link {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  min-width: 218px;
  letter-spacing: 0.3px;
}
.services-overview .wp-block-button {
  padding-top: 15px;
}
.services-overview .wp-block-button .wp-block-button__link {
  min-width: 218px;
  font-size: 12px;
}
.manufacturing-slider h2,
.custom-products h2 {
  font-family: 'PT Sans Narrow', sans-serif;
  position: relative;
  padding-bottom: 10px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  color: #ed1c24;
  font-weight: 400;
  min-width: 250px;
  text-align: center;
  margin-bottom: 1.57rem;
}
.manufacturing-slider h2 strong,
.custom-products h2 strong {
  color: #3e0000;
}
.manufacturing-slider h2::before,
.custom-products h2::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: #3e0000;
}
.manufacturing-slider {
  background: #f4f5f4;
}
.manufacturing-slider .heading {
  padding: 0 12rem;
}
@media (max-width: 991px) {
  .manufacturing-slider .heading {
    padding: 0 15px;
  }
}
.manufacturing-slider .heading p {
  color: #737b7d;
  font-family: 'PT Sans Narrow', sans-serif;
}
.manufacturing-slider .custom-product-slider {
  position: relative;
}
@media (max-width: 991px) {
  .manufacturing-slider .container {
    padding: 0 100px;
  }
}
.manufacturing-slider .slick-arrow {
  overflow: hidden;
  background-color: transparent;
  border: none;
  color: transparent;
  width: 70px;
  height: 65px;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.manufacturing-slider .slick-next {
  background-image: url("../img/right.svg");
  right: -80px;
}
.manufacturing-slider .slick-prev {
  background-image: url("../img/left.svg");
  left: -80px;
}
.custom-products .heading {
  padding-bottom: 32px;
}
.custom-products h4 {
  position: relative;
  min-width: 200px;
  width: fit-content;
  margin-bottom: 32px;
  padding-bottom: 5px;
  font-weight: 700;
}
.custom-products h4::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: #ed1c24;
}
.custom-products p,
.custom-products h4 {
  font-family: 'PT Sans Narrow', sans-serif;
}
.custom-products .product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
}
@media (max-width: 991px) {
  .custom-products .product-list {
    gap: 78px;
  }
}
.custom-products .product-list .product {
  width: calc(33% - 50px);
}
@media (max-width: 767px) {
  .custom-products .product-list .product {
    width: 100%;
  }
}
.custom-products .product-list .product img {
  width: 100%;
  margin-bottom: 30px;
}
.custom-products .product-list p {
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .single-custom-product {
    padding-top: 50px;
  }
}
.single-custom-product h1 {
  font-family: 'PT Sans Narrow', sans-serif;
  position: relative;
  padding-bottom: 10px;
  width: fit-content;
  color: #3e0000;
  font-weight: 700;
  min-width: 250px;
  text-align: left;
  margin-bottom: 2rem;
}
.single-custom-product h1 span {
  color: #ed1c24;
  font-weight: 400;
}
.single-custom-product h1::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: #3e0000;
}
.single-custom-product p {
  color: #737b7d;
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 16px;
}
.single-custom-product .post-content {
  margin-bottom: 35px;
}
@media (max-width: 767px) {
  .single-custom-product .main-image {
    padding-top: 2rem;
  }
}
.single-custom-product .main-image img {
  width: 100%;
}
.single-custom-product .gallery-images {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  position: relative;
}
@media (max-width: 767px) {
  .single-custom-product .gallery-images {
    overflow: hidden;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.single-custom-product .gallery-images img {
  max-width: calc(25% - 15px);
  display: inline-block;
}
.single-custom-product .gallery-images img:hover {
  cursor: pointer;
}
.single-custom-product .gallery-images .slick-arrow {
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  overflow: hidden;
  text-indent: 9999999px;
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .single-custom-product .gallery-images .slick-arrow {
    width: 30px;
    height: 30px;
    z-index: 11;
  }
}
.single-custom-product .gallery-images .slick-arrow.slick-next {
  background-image: url("../img/right.svg");
  right: -80px;
}
@media (max-width: 991px) {
  .single-custom-product .gallery-images .slick-arrow.slick-next {
    right: -35px;
  }
}
@media (max-width: 767px) {
  .single-custom-product .gallery-images .slick-arrow.slick-next {
    right: 0;
  }
}
.single-custom-product .gallery-images .slick-arrow.slick-prev {
  background-image: url("../img/left.svg");
  left: -80px;
}
@media (max-width: 991px) {
  .single-custom-product .gallery-images .slick-arrow.slick-prev {
    left: -35px;
  }
}
@media (max-width: 767px) {
  .single-custom-product .gallery-images .slick-arrow.slick-prev {
    left: 0;
  }
}
